@use 'scss_mixins/tools.media-query' as *;

.grid-filters {
  height: 0;
}
.search-section .search-form .form-group {
  position: relative;
}

.search-form .form-group-submit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  float: left;
  row-gap: 5px;

  @include mq(desktop) {
    flex-direction: row;
    align-items: center;
    float: right;
    column-gap: 10px;
    row-gap: 10px;
  }
}
