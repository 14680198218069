// meta menu overrides
#navigation #secondary-navigation {
  @media screen and (min-width: 800px) {
    display: inline-flex;
  }
}

#navigation #secondary-navigation li a {
  display: inline-flex;
  align-items: center;
}
