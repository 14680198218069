.news-pagination {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  column-gap: 2rem;
  row-gap: 0.5rem;
  list-style-type: none;
}

.news-pagination__item {
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-weight: 700;
  line-height: 1;
}

.news-pagination__item a:focus-visible {
  outline: 2px solid var(--color-black) !important;
  outline-offset: 3px;
}

.news-pagination__item--previous > a::before,
.news-pagination__item--next > a::after {
  position: relative;
  top: -2px;
  color: var(--color-red);
  cursor: pointer;
}

.news-pagination__item--previous > a::before {
  content: '<';
}

.news-pagination__item--next > a::after {
  content: '>';
}

.news-pagination__item--previous > a:hover::before,
.news-pagination__item--previous > a:focus-visible::before,
.news-pagination__item--next > a:focus-visible::after,
.news-pagination__item--next > a:hover::after {
  color: var(--color-black) !important;
}
