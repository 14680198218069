// loading animation ------------------------------------------------
$ciColor: var(--ci-color);
$bgColor: rgba(255, 255, 255, 0.8);
$loaderSize: 120px;

.animation-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: calc(20vh - #{$loaderSize} / 2);
  background-color: $bgColor;
}

.loader {
  position: relative;
  width: $loaderSize;
  height: $loaderSize;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.loader .circular {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  transform-origin: center center;
  animation: rotate 2s linear infinite;
}

.loader .path {
  animation: dash 1.5s ease-in-out infinite;
  stroke: $ciColor;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
