@use 'scss_mixins/tools.focus-styles' as *;
@use 'scss_mixins/tools.media-query' as *;
@use '../../../../node_modules/@3pc/consent-overlay/lib/style/consent-overlay';

@mixin transition($speed) {
  transition: all $speed ease;
}
@mixin rotate($deg) {
  transform: rotate($deg);
}

.video-player__content-trigger {
  @include focus-outline(3px, white, -0.2em);

  width: 100%;
  padding: 15px 20px 10px;
  color: var(--color-white);
  font-weight: 600;
  font-size: 2rem;
  line-height: 1;
  text-align: left;
  background-color: var(--color-red);
  border: none;

  &:hover,
  &:focus-visible {
    background-color: var(--color-black);
  }

  &[aria-expanded='true'] .video-player__content-trigger-icon {
    &::before {
      @include rotate(180deg);
      @include transition(0.5s);
    }
    &::after {
      @include rotate(90deg);
      @include transition(0.5s);
    }
  }
}

.video-player__content-trigger-icon {
  position: relative;
  margin-left: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 18px;
    height: 2px;
    background: var(--color-white);
    border-radius: 3px;
    @include transition(0.5s);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 8px;
    width: 2px;
    height: 18px;
    background: var(--color-white);
    border-radius: 3px;
    @include transition(0.5s);
  }
}

.video-player__content-panel-inner {
  padding: 30px 20px 0;
}

.video-player__content-panel-inner > :first-child {
  margin-top: 0;
}

.video-player__video {
  line-height: 0;
}

.video-player__iframe {
  width: 100%;
  aspect-ratio: 16/9;
}

.video-player .video-js {
  &:hover .vjs-big-play-button {
    background-color: var(--color-black);
  }

  .vjs-big-play-button {
    @include focus-outline($color: 'red');
    width: 5rem;
    height: 5rem;
    background-color: var(--color-red);
    border: none;
    border-radius: 50%;

    .vjs-icon-placeholder {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }

    .vjs-icon-placeholder::before {
      position: absolute;
      top: 33%;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 4rem;
    }

    &:focus-visible,
    &:hover {
      background-color: var(--color-red);
    }
  }

  .vjs-menu-item {
    word-break: break-word;
    hyphens: auto;
  }

  .vjs-modal-dialog-content select {
    color: var(--color-black);
  }

  .vjs-track-setting > select {
    @include mq(tablet, max) {
      max-width: 9em;
    }
  }

  .vjs-text-track-settings fieldset span > select {
    @include mq(tablet) {
      max-width: unset;
    }
  }
}
