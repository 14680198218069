@mixin reset-btn {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.autocomplete-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 3;
  z-index: 105;
  width: 100%;
  margin-top: 15px !important;
  margin-bottom: 0;
  padding: 10px 0;
  color: white;
  list-style: none;
  background-color: #e70036;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 16px;
    display: block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 10px solid #e70036;
  }
  // Matching phrase
  strong {
    margin: 0;
    font-weight: 400;
  }

  // Items in suggestion list
  .autocomplete-suggestion-btn {
    @include reset-btn;

    display: block;
    width: 100%;
    padding: 5px 16px;
    overflow: hidden;
    color: white;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: inherit;
    white-space: nowrap;
    text-align: left;
    text-transform: inherit;
    cursor: pointer;

    &:hover,
    &.focus-visible {
      background-color: #222222;
    }

    &:hover span,
    &.focus-visible span {
      color: white;
    }

    // Matching phrase
    &:not(:hover) strong {
      color: white;
    }
  }

  // Selected item
  .autocomplete-selected {
    background-color: white;
  }

  // we do not use groups, but style them anyway
  .autocomplete-group {
    padding: 0.5rem 0.25rem;
  }

  .autocomplete-group strong {
    display: block;
    border-bottom: 1px solid #999;
  }

  // udk overwites
  span {
    padding: 0 !important;
    color: white !important;

    &::after {
      display: none;
    }

    &:hover {
      color: white !important;
    }
  }

  li {
    border: none !important;
  }
}

.typeahead-hint {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  color: #bfbfbf;
  pointer-events: none;
}

#grid-search .autocomplete-suggestions {
  top: 30px;
}
