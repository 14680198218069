.datepicker button {
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
}

.datepicker button.disabled {
  cursor: default;
}

.datepicker .datepicker__button-date {
  display: block;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
}

.datepicker .datepicker__button-date:hover,
.datepicker .datepicker__button-date:focus-visible {
  color: #fff !important;
  background: #b2002b;
}

.datepicker .datepicker__button-date.disabled {
  color: #b2002b !important;
}

.datepicker .datepicker__button-date.disabled:hover,
.datepicker .datepicker__button-date.disabled:focus-visible {
  color: #b2002b !important;
  background: none;
}

.datepicker__button[hidden] {
  display: none !important;
}
