@use 'scss_mixins/tools.focus-styles' as *;

.consent-overlay {
  --consent-overlay-color-controls-active: var(--color-red);

  &__opt-in {
    padding: 40px 20px;
  }

  &__opt-out {
    padding: 10px 0;
  }

  [data-accept] {
    @include focus-outline();

    padding: 2px 5px;
    font-weight: 700;
    font-size: 1.2em;
    background: none;
    border: 1px solid #fff;

    &:hover,
    &:focus-visible {
      color: var(--color-black);
      background: var(--color-white);
    }
  }
}

[data-consent-type='embed'][data-consent-subtype='vimeo']
  .consent-overlay__content::after,
[data-consent-type='embed'][data-consent-subtype='youtube']
  .consent-overlay__content::after {
  content: '';
  padding: 0;
}

[data-consent-type='embed'][data-consent-subtype='vimeo']
  .consent-overlay__content
  > *,
[data-consent-type='embed'][data-consent-subtype='youtube']
  .consent-overlay__content
  > * {
  position: static;
}
