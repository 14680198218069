.grid-item header h2,
.grid-item header h3 {
  hyphens: auto;
}

.col-content .grid-item.grid-item--third {
  @media (min-width: 992px) and (min-width: 992px) {
    width: 33.33%;
  }
}

@media (min-width: 992px) {
  .col-content .grid .grid-item.grid-item--third:nth-child(3n + 1) {
    clear: both;
  }

  .col-content .grid:not(.is-person-grid) .grid-item:nth-child(4n + 1) {
    clear: inherit;
  }

  .col-content .grid .grid-item:nth-child(4n) {
    float: left;
  }
}
