.grid-filters label,
.search-facet label {
  display: inline-block;
  height: 21px;
  margin-right: 3px;
  margin-bottom: 4px;
  padding: 1px 5px;
  color: #ffffff;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: -0.1px;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  background: #c9c9c9;
  background-image: none;
  border: 1px solid transparent;
  border: 0;
  border-color: #cccccc;
  border-radius: 0;
  cursor: pointer;
  touch-action: manipulation;
}

.grid-filters label:focus,
.search-facet label:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.grid-filters label:hover,
.search-facet label:hover {
  color: #ffffff;
  background: #222222;
  border-color: #adadad;
}

.grid-filters label:active,
.search-facet label:active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.grid-filters label.disabled,
.search-facet label.disabled,
.grid-filters label[disabled],
.search-facet label[disabled],
fieldset[disabled] .grid-filters label,
fieldset[disabled] .search-facet label {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  pointer-events: none;
}

.webfont-loaded .grid-filters label,
.webfont-loaded .search-facet label {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
}

.grid-filters .active label,
.search-facet .active label {
  background-color: #e70036;
}

.grid-filters .active label::after,
.search-facet .active label::after {
  content: '\f418';
  position: relative;
  top: 0;
  display: inline-block;
  height: 18px;
  overflow: hidden;
  font-weight: normal;
  font-size: 20px;
  font-family: 'Genericons', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: inherit;
  vertical-align: top;
  vertical-align: -0.2em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  speak: none;
}

.grid-filters .active label:hover::after,
.search-facet .active label:hover::after {
  content: '\f405';
}
