@use 'scss_mixins/tools.focus-styles' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_functions/tools.pxtorem' as *;

@mixin transition($speed) {
  transition: all $speed ease;
}
@mixin rotate($deg) {
  transform: rotate($deg);
}

.accordion {
  margin-bottom: 3rem;
}

.accordion__heading {
  font-size: pxtorem(30px);
  line-height: 1.3;
}

.accordion__list {
  padding-left: 0;
  list-style-type: none;
  border-top: 1px solid var(--color-black);

  ul {
    list-style-type: disc;
  }
}

.accordion__intro {
  font-size: pxtorem(18px);
}

.accordion__intro,
.accordion__list {
  max-width: 95rem;
}

.detail-section .accordion__intro,
.detail-section .accordion__list {
  max-width: 100%;
}

.accordion__item {
  border-bottom: 1px solid var(--color-black);

  > h3 {
    margin: 0;
  }
}

.accordion__content-trigger {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  padding-top: 1.8rem;
  padding-bottom: 1.5rem;
  color: var(--color-black);
  text-align: left;
  background: none;
  border: none;
  outline: none !important;

  &:focus-visible,
  &:hover {
    color: var(--color-red);
  }

  &[aria-expanded='true'] .accordion__content-trigger-icon {
    &::before {
      @include rotate(180deg);
      @include transition(0.5s);
    }
    &::after {
      @include rotate(90deg);
      @include transition(0.5s);
    }
  }
}

.accordion__content-trigger-icon {
  position: relative;
  margin-left: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    right: 100%;
    width: 18px;
    height: 2px;
    background: currentColor;
    border-radius: 3px;
    @include transition(0.5s);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 8px;
    width: 2px;
    height: 18px;
    background: currentColor;
    border-radius: 3px;
    @include transition(0.5s);
  }
}

.accordion__content-panel-inner {
  display: grid;
  gap: 3rem;
  padding: 1rem 0 2rem;
}

.accordion__content-panel-inner--flex {
  @include mq(tablet, min) {
    grid-template-columns: 1fr 1fr;
  }
}

.accordion__media {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.accordion__media img {
  width: 100%;
  height: auto;
}

.accordion__text {
  h3:first-child,
  h4:first-child {
    margin-top: 5px;
  }
}

.accordion figcaption {
  margin-top: 5px;
}

.accordion__figcaption-description {
  font-size: 14px;
  font-style: normal;
}

.accordion__figcaption-copyright {
  color: #999;
  font-size: 14px;
  font-style: normal;
}
